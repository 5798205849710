<template>
  <section class="views">
    <headers select="2"></headers>
    <div class="views_content">
      <div class="views_banner">
        <img :src="require('@/assets/image/hodgepodge/h5banner.jpg')" alt="" />
      </div>
      <div class="viewsBoxTab">
        <div class="viewsTab" ref="timeSlide">
          <ul>
            <li
              v-for="(item, index) in gradeColumns"
              :key="index"
              @click="tabBut(index)"
              :class="select === index ? 'select' : ''"
              :ref="`tab${index}Ref`"
            >
              <div>{{ item.name }}</div>
              <div>({{ item.fzhu }})</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="viewsList">
        <transition name="van-slide-left">
          <ul>
            <li
              v-for="(item, index) in pdfList"
              :key="index"
              @click="pdfBut(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import headers from '@/components/header/h_header'
const isIOS = /(iPhone|iPad|iPod|iOS)/.test(navigator.userAgent)
export default {
  name: 'views',
  data() {
    return {
      gradeColumns: [
        {
          name: '幼稚園初級組',
          fzhu: 'K1-K2'
        },
        {
          name: '幼稚園高級組',
          fzhu: 'K3'
        },
        {
          name: '小學初級組',
          fzhu: 'P1-P2'
        },
        {
          name: '小學中級組',
          fzhu: 'P3-P4'
        },
        {
          name: '小學高級組',
          fzhu: 'P5-P6'
        },
        {
          name: '初中組',
          fzhu: 'S1-S3'
        },
        {
          name: '閃亮組',
          fzhu: '特殊學校'
        },
      ],
      pdf: {
        new0: [
          {
            name: '比賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/a2609894f327333f4dc0375c6b69d481.pdf'
          },
        ],
        new1: [
          {
            name: '比賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/d297e85be9403e1ca2291da3786f631a.pdf'
          },
        ],
        new2: [
          {
            name: '比賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/ef6e3e2791d5c23f0bbe87a8cbbef735.pdf'
          },
        ],
        new3: [
          {
            name: '初賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/536afb2acb083a678eec30f2f720bda0.pdf'
          },
          {
            name: '複賽及決賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/93cb98211947fe7fa2a102a3434be469.pdf'
          },
        ],
        new4: [
          {
            name: '初賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/abcb0e61291efd80f581a6dd1b277414.pdf'
          },
          {
            name: '複賽及決賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/cb8a7f231c46d3c4948dda9ac8d49b61.pdf'
          },
        ],
        new5: [
          {
            name: '初賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/42187303515c04fa62bf7e0b20176b9d.pdf'
          },
          {
            name: '複賽及決賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/99e02a001afe9ef9e6f5018fec70d08d.pdf'
          },
        ],
        new6: [
          {
            name: '比賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/6adfa912f79e9387d069a213f85f96da.pdf'
          },
        ]
      },
      pdfList: [],
      select: 0
    }
  },
  computed: {},
  components: {
    headers
  },
  // watch: {
  //   select(val){
  //     if(val){
  //       console.log(val);
  //       this.pdfList = [];
  //       this.pdfList = this.pdf[`new${val}`]
  //     }
  //   }
  // },
  created() {
    this.pdfList = this.pdf[`new0`]
  },
  mounted() {},
  methods: {
    getPdf(e) {
      this.$axios({
        url: e.url,
        method: 'get',
        responseType: 'blob'
      })
        .then((response) => {
          this.$toast.clear()
          // this.$toast.success('開始下載');
          // 将文件流转化为 Blob 对象，并创建 a 标签进行下载
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载链接
          downloadElement.href = href
          downloadElement.download = e.name // 文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 下载
          document.body.removeChild(downloadElement) // 下载完成后移除元素
          window.URL.revokeObjectURL(href) // 释放占用的内存空间
        })
        .catch((error) => {
          this.$toast.clear()
          console.error(error) // 处理异常情况
        })
    },
    pdfBut(e) {
      // this.$dialog
      //   .alert({
      //     title: '提示',
      //     message: '誦材暫未開放下載',
      //     confirmButtonText: '確認'
      //   })
      //   .then(() => {
      //     // on close
      //   })
      // return
      if (e.url) {
        let a = document.createElement('a')
        a.href = e.url
        a.click()
        // if(isIOS){
        // this.getPdf(e);
        // let a = document.createElement('a')
        // a.href = e.url
        // a.click()
        // }else{
        //   let url = 'https://chineserd.cn/h5/html/regulations/index.html?url=' + e.url
        //   let a = document.createElement('a')
        //   a.href = url
        //   a.click()
        // }
      } else {
        this.$dialog
          .alert({
            title: '提示',
            message: '決賽誦材暫未開放下載',
            confirmButtonText: '確認'
          })
          .then(() => {
            // on close
          })
      }
    },
    tabBut(e) {
      this.select = e
      this.pdfList = this.pdf[`new${e}`]
      // 触发滑动方法
      this.scrollLeftTo(e)
    },
    // 滑动
    scrollLeftTo(name) {
      const ref = `tab${name}Ref`
      const nav = this.$refs.timeSlide
      console.log('额:', this.$refs.timeSlide.offsetWidth)
      const title = this.$refs[ref][0]
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2
      nav.scrollLeft = to
    }
  }
}
</script>

<style lang="scss" scoped>
.views {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(225deg, #ff8310 0%, #fe840f 100%);
  .views_content::-webkit-scrollbar {
    display: none;
  }
  .views_content {
    width: 100%;
    height: calc(100% - 0.92rem);
    overflow: scroll;

    .views_banner {
      width: 100%;
      display: grid;

      img {
        width: 100%;
      }
    }

    .viewsTab::-webkit-scrollbar {
      display: none;
    }

    .viewsBoxTab {
      width: 100%;
    }

    .viewsTab {
      width: 100%;
      padding: 0.16rem 0;
      box-sizing: border-box;
      overflow-y: scroll;
      margin-top: -6%;

      ul {
        width: 100%;
        height: 1.28rem;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;

        li {
          width: 3.14rem;
          height: 100%;
          display: flex;
          align-content: center;
          align-items: center;
          flex-wrap: wrap;
          font-family: PingFang SC-Semibold, PingFang SC;
          line-height: 0.38rem;
          flex: 0 0 auto;
          background: #d34a00;

          div {
            width: 100%;
          }

          div:nth-child(1) {
            font-size: 0.28rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.33rem;
          }

          div:nth-child(2) {
            font-size: 0.22rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.26rem;
          }
        }

        .select {
          font-size: 0.32rem;
          font-weight: 600;
          color: #903300;
          padding: 0.16rem 0;
          background: url('~@/assets/image/hodgepodge/tabbuttom.png') no-repeat;
          background-size: 100% 100%;

          div:nth-child(1) {
            font-size: 0.32rem !important;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600 !important;
            color: #903300;
            line-height: 0.38rem !important;
          }

          div:nth-child(2) {
            font-size: 0.26rem !important;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600 !important;
            color: #903300;
            line-height: 0.3rem !important;
          }
        }
      }
    }

    .viewsList {
      width: 100%;
      margin-top: 0.32rem;

      padding: 0.32rem;
      box-sizing: border-box;

      ul {
        width: 100%;

        li {
          width: 100%;
          height: 2.48rem;
          background: #fff8de;
          box-shadow: 0px 0px 0.08rem 0px rgba(126, 56, 0, 0.26);
          border: 0.08rem solid #fff0e1;
          border-radius: 0.32rem;
          margin-bottom: 0.32rem;

          font-size: 0.48rem;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff782f;
          line-height: 0.56rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
