<template>
	<section class="totalBox" v-show="viewShow">
		<pcView v-if="isPc" />
		<h5View v-else /> 
	</section>
</template>


<script> 
import { mapState } from 'vuex';  
import pcView from '@/components/material/pc/index'
import h5View from '@/components/material/v4/index'

export default {
	components:{},
	name: 'material',
	data() {
		return { 
			
		};
	},
	components: {
		pcView,h5View
	},
	watch: { 
		
	}, 
	created() {  
	},
	mounted() {
		
	},
	computed: {
		isPc() { 
		  return this.$store.state.isPc;
		},
		viewShow(){
		  return this.$store.state.viewShow;
		}
	},
	computed: {
	  ...mapState(['isPc','viewShow'])
	},
};
</script>

<style lang="scss" scoped="scoped"> 
.totalBox{
	width: 100%;
	height: 100%;
}
</style>
